import * as Types from "../../../actions/actionTypes";

const initialState = {
  allNuggetsFetched: false,
};

for (let i = 1; i < 8; i++) {
  initialState["vendorSalesNugget" + i] = {
    data: {
      chart_info: {
        chart_label: "",
        data: {},
      },
    },
    loading: true,
    error: "",
  };
}

function vendorSalesNuggetReducer(state = initialState, action) {
  const newState = { ...state };
  for (let i = 1; i < 8; i++) {
    let nuggetNumber = "vendorSalesNugget" + i;

    if (
      action.type ===
      Types.vendorSalesNuggetActionTypes[
        `VENDOR_SALES_NUGGET_${i}_DATA_LOADING`
      ]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        loading: action.payload,
      };
      return newState;
    }

    if (
      action.type ===
      Types.vendorSalesNuggetActionTypes[`VENDOR_SALES_NUGGET_${i}_DATA_LOADED`]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        data: action.payload,
        error: "",
      };
      return newState;
    }
    if (
      action.type ===
      Types.vendorSalesNuggetActionTypes[
        `VENDOR_SALES_NUGGET_${i}_DATA_LOAD_ERROR`
      ]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        error: action.payload,
      };
      return newState;
    }
  }

  if (action.type === Types.VENDOR_SALES_NUGGETS_FETCHED) {
    return {
      ...state,
      allNuggetsFetched: action.payload,
    };
  }
  if (action.type === Types.RESET_VENDOR_SALES_CHART_DATA) {
    return initialState;
  }
  return state;
}

export default vendorSalesNuggetReducer;
