import * as Types from "../../../actions/actionTypes";

const initialState = {
  allNuggetsFetched: false,
};

for (let i = 1; i < 6; i++) {
  initialState["vendorAdsNugget" + i] = {
    data: {
      chart_info: {
        chart_label: "",
        data: {},
      },
    },
    loading: true,
    error: "",
  };
}

function vendorAdsNuggetReducer(state = initialState, action) {
  const newState = { ...state };
  for (let i = 1; i < 6; i++) {
    let nuggetNumber = "vendorAdsNugget" + i;

    if (
      action.type ===
      Types.vendorAdsNuggetActionTypes[`VENDOR_ADS_NUGGET_${i}_DATA_LOADING`]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        loading: action.payload,
      };
      return newState;
    }

    if (
      action.type ===
      Types.vendorAdsNuggetActionTypes[`VENDOR_ADS_NUGGET_${i}_DATA_LOADED`]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        data: action.payload,
        error: "",
      };
      return newState;
    }
    if (
      action.type ===
      Types.vendorAdsNuggetActionTypes[`VENDOR_ADS_NUGGET_${i}_DATA_LOAD_ERROR`]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        error: action.payload,
      };
      return newState;
    }
  }
  if (action.type === Types.VENDOR_ADS_NUGGETS_FETCHED) {
    return {
      ...state,
      allNuggetsFetched: action.payload,
    };
  }

  if (action.type === Types.RESET_VENDOR_ADS_CHART_DATA) {
    return initialState;
  }
  return state;
}

export default vendorAdsNuggetReducer;
