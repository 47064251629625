import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
  errors: {},
};
const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.NOTIFICATION_LOADING: {
      return {
        ...state,
        loading: true,
        data: [],
        errors: {},
      };
    }
    case Types.NOTIFICATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };
    }
    case Types.NOTIFICATION_FAILURE: {
      return {
        ...state,
        loading: false,
        data: [],
        errors: action.payload,
      };
    }
    case Types.NOTIFICATION_MARKED_LOADING: {
      return {
        ...state,
        loading: true,
        data: [],
        errors: {},
      };
    }
    case Types.NOTIFICATION_MARKED_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };
    }
    case Types.NOTIFICATION_MARKED_FAILURE: {
      return {
        ...state,
        loading: false,
        data: [],
        errors: action.payload,
      };
    }
    default:
      return state;
  }
};
export default notificationReducer;
