import * as Types from "../actions/actionTypes";

const initialState = {
  loading: false,
  data: [],
  errors: {},
};
const alertSetupReducer = (state = initialState, action) => {
  // console.log("action : ", action);
  switch (action.type) {
    case Types.SETTING_LOADING: {
      return {
        ...state,
        loading: true,
        data: [],
        errors: {},
      };
    }
    case Types.SETTING_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        errors: {},
      };
    }
    case Types.SETTING_FAILURE: {
      return {
        ...state,
        loading: false,
        data: [],
        errors: action.payload,
      };
    }
    case Types.SETTING_EDIT_LOADING: {
      return {
        ...state,
        loading: true,
        data: [],
        errors: {},
      };
    }
    case Types.SETTING_EDIT_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        errors: {},
      };
    }
    case Types.SETTING_EDIT_FAILURE: {
      return {
        ...state,
        loading: false,
        data: [],
        errors: action.payload,
      };
    }
    default:
      return state;
  }
};
export default alertSetupReducer;
