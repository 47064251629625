import * as Types from "../../../actions/actionTypes";

const initialState = {};

for (let i = 1; i < 9; i++) {
  initialState["vendorAnalyticsChart" + i] = {
    loading: true,
    data: {
      chart_label: "",
      data: [],
      legends: [],
      chart_info: {
        chart_label: "",
        data: {},
      },
    },
    error: "",
  };
}

function vendorAnalyticsChartReducer(state = initialState, action) {
  const newState = { ...state };

  for (let i = 1; i < 9; i++) {
    const chartNumber = "vendorAnalyticsChart" + i;

    if (
      action.type ===
      Types.vendorAnalyticsChartActionTypes[
        `VENDOR_ANALYTICS_CHART_${i}_DATA_LOADING`
      ]
    ) {
      newState[chartNumber] = {
        ...state[chartNumber],
        loading: action.payload,
      };
      return newState;
    }
    if (
      action.type ===
      Types.vendorAnalyticsChartActionTypes[
        `VENDOR_ANALYTICS_CHART_${i}_DATA_LOADED`
      ]
    ) {
      newState[chartNumber] = {
        ...state[chartNumber],
        data: action.payload,
        error: "",
      };
      return newState;
    }
    if (
      action.type ===
      Types.vendorAnalyticsChartActionTypes[
        `VENDOR_ANALYTICS_CHART_${i}_DATA_LOAD_ERROR`
      ]
    ) {
      newState[chartNumber] = {
        ...state[chartNumber],
        error: action.payload,
      };
      return newState;
    }
  }

  if (action.type === Types.RESET_VENDOR_ANALYTICS_CHART_DATA) {
    return initialState;
  }

  return state;
}

export default vendorAnalyticsChartReducer;
