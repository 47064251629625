import * as Types from "../actionTypes";
import axios from "../../../utils/axios";
import { getHeaders } from "../../../utils";

export const getAllIdentifiers = () => (dispatch) => {
  dispatch({ type: Types.DATA_IDENTIFIERS_LOADING });

  axios
    .get("/charts/data/available-identifiers/", { headers: getHeaders() })
    .then((res) => {
      dispatch({
        type: Types.DATA_IDENTIFIERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Types.DATA_IDENTIFIERS_FAILURE,
        payload: error?.response ? error.response?.data : {},
      });
    });
};
