import * as Types from "../actions/actionTypes";

const initialState = {
  accountingReconciliation: {
    loading: false,
    data: [],
    errors: {},
  },
  procurementInvoicing: {
    loading: false,
    data: [],
    errors: {},
  },
  payments: {
    loading: false,
    data: [],
    errors: {},
  },
  zipDownload: {
    loading: false,
    data: [],
    errors: {},
  },
  receivables: {
    loading: false,
    data: [],
    errors: {},
  },
  receivablePayment: {
    loading: false,
    data: [],
    errors: {},
  },
  slots: {
    loading: false,
    data: [],
    errors: {},
  },
};

const accountingReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.ACCOUNTING_RECONCILIATION_LOADING: {
      return {
        ...state,
        accountingReconciliation: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.ACCOUNTING_RECONCILIATION_SUCCESS: {
      return {
        ...state,
        accountingReconciliation: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.ACCOUNTING_RECONCILIATION_FAILURE: {
      return {
        ...state,
        accountingReconciliation: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.PROCUREMENT_INVOICING_LOADING: {
      return {
        ...state,
        procurementInvoicing: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.PROCUREMENT_INVOICING_SUCCESS: {
      return {
        ...state,
        procurementInvoicing: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.PROCUREMENT_INVOICING_FAILURE: {
      return {
        ...state,
        procurementInvoicing: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.ACCOUNTING_PAYMENTS_LOADING: {
      return {
        ...state,
        payments: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.ACCOUNTING_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.ACCOUNTING_PAYMENTS_FAILURE: {
      return {
        ...state,
        payments: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.ACCOUNTING_ZIP_FILE_LOADING: {
      return {
        ...state,
        zipDownload: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.ACCOUNTING_ZIP_FILE_SUCCESS: {
      return {
        ...state,
        zipDownload: {
          loading: false,
          errors: {},
        },
      };
    }
    case Types.ACCOUNTING_ZIP_FILE_FAILURE: {
      return {
        ...state,
        zipDownload: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.ACCOUNTING_RECEIVABLES_LOADING: {
      return {
        ...state,
        receivables: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.ACCOUNTING_RECEIVABLES_SUCCESS: {
      return {
        ...state,
        receivables: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.ACCOUNTING_RECEIVABLES_FAILURE: {
      return {
        ...state,
        receivables: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.RECEIVABLE_PAYMENT_LOADING: {
      return {
        ...state,
        receivablePayment: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.RECEIVABLE_PAYMENT_SUCCESS: {
      return {
        ...state,
        receivablePayment: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.RECEIVABLE_PAYMENT_FAILURE: {
      return {
        ...state,
        receivablePayment: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.RESET_RECEIVABLE_PAYMENT: {
      return {
        ...state,
        receivablePayment: {
          loading: false,
          data: [],
          errors: {},
        },
      };
    }
    case Types.WAREHOUSES_SLOTS_LOADING: {
      return {
        ...state,
        slots: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.WAREHOUSES_SLOTS_SUCCESS: {
      return {
        ...state,
        slots: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.WAREHOUSES_SLOTS_FAILURE: {
      return {
        ...state,
        slots: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default accountingReducer;
