import * as Types from "../actions/actionTypes";

const initialState = {
  tickets: {
    loading: false,
    data: [],
    errors: {},
  },
  isCreateTicketLoading: false,
  isCommentCreationLoading: false,
  isReopenTicketLoading: false,
  isCloseTicketLoading: false,
};

const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.TICKETS_LOADING: {
      return {
        ...state,
        tickets: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.TICKETS_SUCCESS: {
      return {
        ...state,
        tickets: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.TICKETS_FAILURE: {
      return {
        ...state,
        tickets: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.CREATE_TICKET_LOADING: {
      return {
        ...state,
        isCreateTicketLoading: action.payload,
      };
    }
    case Types.CREATE_COMMENT_LOADING: {
      return {
        ...state,
        isCommentCreationLoading: action.payload,
      };
    }
    case Types.REOPEN_TICKET_LOADING: {
      return {
        ...state,
        isReopenTicketLoading: action.payload,
      };
    }
    case Types.CLOSE_TICKET_LOADING: {
      return {
        ...state,
        isCloseTicketLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default ticketReducer;
