import * as Types from "../actions/actionTypes";

const initialState = {
  latestReviews: {
    loading: false,
    data: [],
    errors: {},
  },
  wordCloud: {
    loading: false,
    data: [],
    errors: {},
  },
};

const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LATEST_REVIEWS_LOADING: {
      return {
        ...state,
        latestReviews: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.LATEST_REVIEWS_SUCCESS: {
      return {
        ...state,
        latestReviews: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.LATEST_REVIEWS_FAILURE: {
      return {
        ...state,
        latestReviews: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    case Types.WORD_CLOUD_LOADING: {
      return {
        ...state,
        wordCloud: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.WORD_CLOUD_SUCCESS: {
      return {
        ...state,
        wordCloud: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.WORD_CLOUD_FAILURE: {
      return {
        ...state,
        wordCloud: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default reviewsReducer;
