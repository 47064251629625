import axios from "../../../utils/axios";
import { getHeaders } from "../../../utils";
import * as Types from "../actionTypes";

export const checkSubscription = () => (dispatch) => {
  dispatch({ type: Types.SUBSCRIPTION_STATUS_LOADING, payload: true });

  axios
    .get("/payment/subscription/", { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.SUBSCRIPTION_STATUS_SUCCESS, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Types.SUBSCRIPTION_STATUS_FAILURE,
        payload: error?.response?.data,
      });
    });
};

export const loadPackages = () => (dispatch) => {
  dispatch({ type: Types.SUBSCRIPTION_PACKAGES_LOADING, payload: true });

  axios
    .get("/payment/packages/", { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.SUBSCRIPTION_PACKAGES_LOADED, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Types.SUBSCRIPTION_PACKAGES_LOADING_ERROR,
        payload: error.response?.data || {},
      });
    });
};

export const loadPaymentAmount = (data) => (dispatch) => {
  dispatch({ type: Types.PAYMENT_AMOUNT_LOADING, payload: true });
  axios
    .post("/payment/payment-amount/", data, { headers: getHeaders() })
    .then((res) => {
      dispatch({ type: Types.PAYMENT_AMOUNT_LOADED, payload: res.data });
    })
    .catch((error) => {
      dispatch({
        type: Types.PAYMENT_AMOUNT_LOADING_ERROR,
        payload: error?.response?.data,
      });
    });
};

export const createPaymentRequest = (data) => (dispatch) => {
  dispatch({ type: Types.TRANSACTION_LOADING, payload: true });

  axios
    .post("/payment/create-payment-link/", data, { headers: getHeaders() })
    .then((res) => {
      dispatch({
        type: Types.TRANSACTION_SUCCESS,
        payload: res.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: Types.TRANSACTION_FAILURE,
        payload: error?.response?.data,
      });
    });
};

// export const unsubscribePlan = () => (dispatch) => {
//   dispatch({ type: Types.SUBSCRIPTION_STATUS_LOADING, payload: true });

//   axios
//     .delete("/payment/subscription/", { headers: getHeaders() })
//     .then((res) => {
//       dispatch({ type: Types.SUBSCRIPTION_STATUS_SUCCESS, payload: res.data });
//     })
//     .catch((error) => {
//       dispatch({
//         type: Types.SUBSCRIPTION_STATUS_FAILURE,
//         payload: error?.response?.data,
//       });
//     });
// };
