import * as Types from "../actions/actionTypes";

const initialState = {
  goal: {
    loading: false,
    data: [],
    errors: {},
  },
  keyword: {
    loading: false,
    data: [],
    errors: {},
  },
};

const performanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.PERFORMANCE_GOAL_LOADING: {
      return {
        ...state,
        goal: {
          loading: true,
          data: [],
          errors: {},
        },
      };
    }
    case Types.PERFORMANCE_GOAL_SUCCESS: {
      return {
        ...state,
        goal: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.PERFORMANCE_GOAL_FAILURE: {
      return {
        ...state,
        goal: {
          loading: false,
          data: [],
          errors: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default performanceReducer;
