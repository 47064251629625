import { VIEWS_CHART_COUNT } from "../../../../utils/constants";
import * as Types from "../../../actions/actionTypes";

const initialState = {
  allNuggetsFetched: false,
};
const start = VIEWS_CHART_COUNT.sales.nugget.start;
const end = VIEWS_CHART_COUNT.sales.nugget.end;

for (let i = start; i < end; i++) {
  initialState["salesNugget" + i] = {
    data: {
      chart_info: {
        chart_label: "",
        data: {},
      },
    },
    loading: true,
    error: "",
  };
}

function salesNuggetReducer(state = initialState, action) {
  const newState = { ...state };
  for (let i = start; i < end; i++) {
    let nuggetNumber = "salesNugget" + i;

    if (
      action.type ===
      Types.salesNuggetActionTypes[`SALES_NUGGET_${i}_DATA_LOADING`]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        loading: action.payload,
      };
      return newState;
    }

    if (
      action.type ===
      Types.salesNuggetActionTypes[`SALES_NUGGET_${i}_DATA_LOADED`]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        data: action.payload,
        loading: false,
        error: "",
      };
      return newState;
    }
    if (
      action.type ===
      Types.salesNuggetActionTypes[`SALES_NUGGET_${i}_DATA_LOAD_ERROR`]
    ) {
      newState[nuggetNumber] = {
        ...state[nuggetNumber],
        error: action.payload,
      };
      return newState;
    }
  }

  if (action.type === Types.SALES_NUGGETS_FETCHED) {
    return {
      ...state,
      allNuggetsFetched: action.payload,
    };
  }
  if (action.type === Types.RESET_SALES_CHART_DATA) {
    return initialState;
  }
  return state;
}

export default salesNuggetReducer;
