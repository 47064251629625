// Icons
import salesIcon from "../../assets/icons/salesWhite.svg"
import salesIcon1 from "../../assets/icons/sales.svg"
import adIcon from "../../assets/icons/adWhite.svg"
import adIcon1 from "../../assets/icons/ad.svg"
import brandIcon from "../../assets/icons/brandWhite.svg"
import brandIcon1 from "../../assets/icons/brand.svg"

import * as Types from "../actions/actionTypes";

const initialState = {
    data: [
        {
            src: salesIcon,
            src1: salesIcon1,
            value: "SELLER_SALES",
            label: "Seller Sales"
        },
        {
            src: adIcon,
            src1: adIcon1,
            value: "SELLER_ADVERTISEMENT",
            label: "Seller Advertisement"
        },
        {
            src: brandIcon,
            src1: brandIcon1,
            value: "SELLER_ANALYTICS",
            label: "Seller Analytics"
        },
        {
            src: salesIcon,
            src1: salesIcon1,
            value: "VENDOR_SALES",
            label: "Vendor Sales"
        },
        {
            src: adIcon,
            src1: adIcon1,
            value: "VENDOR_ADVERTISEMENT",
            label: "Vendor Advertisement"
        },
        {
            src: brandIcon,
            src1: brandIcon1,
            value: "VENDOR_ANALYTICS",
            label: "Vendor Analytics"
        },
    ],
    activeTab: {
        value: "SELLER_SALES",
        label: "Seller Sales"
    }
}

function sidebarToggleReducer(state = initialState, action) {
    switch (action.type) {
        case Types.SIDEBAR_TOGGLE: {
            return {
                ...state,
                activeTab: action.payload,
            };
        }
        default:
            return state;
    }
}

export default sidebarToggleReducer;