import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import queryString from "query-string";
// import { LOGIN_PAGE } from "./urls";
import { getFCMToken, onMessageListener } from "../firebase/firebaseconfig";
import { updateFCMToken } from "../store/actions/auth/authActions";
import { defaultToast } from "../utils/toast";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = useSelector((state) => state.auth);
  const firstRender = useRef(true);

  useEffect(() => {
    const fetchToken = async () => {
      const token = await getFCMToken();
      if (token) {
        updateFCMToken({ fcm_token: token });
      }
    };

    if (auth?.isAuthenticated && firstRender.current) {
      fetchToken();
      firstRender.current = false;
    }
  }, [auth?.isAuthenticated]);

  onMessageListener()
    .then((payload) => {
      defaultToast(payload?.data?.title + "<br />" + payload?.data?.body);
    })
    .catch((err) => console.log("failed: ", err));

  return (
    <Route
      {...rest}
      render={(props) => {
        const params = {
          ...queryString.parse(props.location.search),
          return_url: props.location?.pathname || "/",
        };

        return (
          <>
            {auth.isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  search: queryString.stringify(params),
                  state: { from: props.location },
                }}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default PrivateRoute;
