import { VIEWS_CHART_COUNT } from "../../../../utils/constants";
import * as Types from "../../../actions/actionTypes";

const initialState = {};
const start = VIEWS_CHART_COUNT.sales.chart.start;
const end = VIEWS_CHART_COUNT.sales.chart.end;

for (let i = start; i < end; i++) {
  initialState["salesChart" + i] = {
    loading: true,
    data: {
      chart_label: "",
      data: [],
      legends: [],
      chart_info: {
        chart_label: "",
        data: {},
      },
    },
    error: "",
  };
}

function salesChartReducer(state = initialState, action) {
  const newState = { ...state };

  for (let i = start; i < end; i++) {
    const chartNumber = "salesChart" + i;

    if (
      action.type ===
      Types.salesChartActionTypes[`SALES_CHART_${i}_DATA_LOADING`]
    ) {
      newState[chartNumber] = {
        ...state[chartNumber],
        loading: action.payload,
      };
      return newState;
    }
    if (
      action.type ===
      Types.salesChartActionTypes[`SALES_CHART_${i}_DATA_LOADED`]
    ) {
      newState[chartNumber] = {
        ...state[chartNumber],
        data: action.payload,
        error: "",
      };
      return newState;
    }
    if (
      action.type ===
      Types.salesChartActionTypes[`SALES_CHART_${i}_DATA_LOAD_ERROR`]
    ) {
      newState[chartNumber] = {
        ...state[chartNumber],
        error: action.payload,
      };
      return newState;
    }
  }

  if (action.type === Types.RESET_SALES_CHART_DATA) {
    return initialState;
  }

  return state;
}

export default salesChartReducer;
