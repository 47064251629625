import { combineReducers } from "redux";
import auth from "./authReducer";

import salesNugget from "./chartReducers/v2/salesNuggetReducer";
import salesChart from "./chartReducers/v2/salesChartReducer";

import adsNugget from "./chartReducers/v2/adsNuggetReducer";
import adsChart from "./chartReducers/v2/adsChartReducer";

import primaryAnalyticsChart from "./chartReducers/v2/primaryAnalyticsChartReducer";
import detailedAnalyticsChart from "./chartReducers/v2/detailedAnalyticsChartReducer";

import template from "./templateReducer";
import dateRange from "./dateRangeReducer";
import theme from "./themeReducer";
import sidebarToggle from "./sidebarToggleReducer";
import payment from "./paymentReducer";
import uploads from "./uploadsReducers";
import accounting from "./accountingReducer";
import productPricing from "./pricingReducer";
import performance from "./performanceReducer";
import reviews from "./reviewsReducer";
import dashboardChart from "./dashboardReducer";
import ticket from "./ticketReducer";
import identifiers from "./chartReducers/identifiersReducer";

// notification
import notifications from "./notificationReducer";

// setupAlerts
import setupALerts from "./alertSetupReducer";

// V1 charts
import sellerSalesNugget from "./chartReducers/v1/sellerSalesNuggetReducer";
import sellerSalesChart from "./chartReducers/v1/sellerSalesChartReducer";
import vendorSalesNugget from "./chartReducers/v1/vendorSalesNuggetReducer";
import vendorSalesChart from "./chartReducers/v1/vendorSalesChartReducer";
import sellerAdsNugget from "./chartReducers/v1/sellerAdsNuggetReducer";
import sellerAdsChart from "./chartReducers/v1/sellerAdsChartReducer";
import vendorAdsNugget from "./chartReducers/v1/vendorAdsNuggetReducer";
import vendorAdsChart from "./chartReducers/v1/vendorAdsChartReducer";
import brandsChart from "./chartReducers/v1/sellerAnalyticsChartReducer";
import vendorAnalyticsChart from "./chartReducers/v1/vendorAnalyticsChartReducer";

const rootReducer = combineReducers({
  auth,

  salesNugget,
  salesChart,

  adsNugget,
  adsChart,

  primaryAnalyticsChart,
  detailedAnalyticsChart,

  template,
  dateRange,
  theme,
  sidebarToggle,
  payment,
  uploads,
  accounting,
  productPricing,
  performance,
  reviews,
  dashboardChart,
  ticket,
  identifiers,

  //notifications
  notifications,

  // setupALerts
  setupALerts,
  
  // V1 charts
  sellerSalesNugget,
  sellerSalesChart,
  sellerAdsNugget,
  sellerAdsChart,
  brandsChart,
  vendorSalesNugget,
  vendorSalesChart,
  vendorAdsNugget,
  vendorAdsChart,
  vendorAnalyticsChart,
});

export default rootReducer;
