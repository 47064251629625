import * as Types from "../actions/actionTypes";

const initialState = {
  packages: {
    loading: false,
    data: {},
    errors: {},
  },
  paymentAmount: {
    loading: false,
    data: {},
    errors: {},
  },
  transaction: {
    loading: false,
    data: {},
    success: false,
    errors: {},
  },
  subscriptionStatus: {
    loading: false,
    data: {},
    errors: {},
  },
};

function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case Types.SUBSCRIPTION_PACKAGES_LOADING: {
      return {
        ...state,
        packages: {
          loading: action.payload,
          errors: {},
          data: {},
        },
      };
    }
    case Types.SUBSCRIPTION_PACKAGES_LOADED: {
      return {
        ...state,
        packages: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.SUBSCRIPTION_PACKAGES_LOADING_ERROR: {
      return {
        ...state,
        packages: {
          loading: false,
          errors: action.payload,
          data: {},
        },
      };
    }
    case Types.PAYMENT_AMOUNT_LOADING: {
      const prevState = { ...state.paymentAmount };
      return {
        ...state,
        paymentAmount: {
          ...prevState,
          loading: action.payload,
        },
      };
    }
    case Types.PAYMENT_AMOUNT_LOADED: {
      return {
        ...state,
        paymentAmount: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.PAYMENT_AMOUNT_LOADING_ERROR: {
      const prevState = { ...state.paymentAmount };
      return {
        ...state,
        paymentAmount: {
          ...prevState,
          loading: false,
          errors: action.payload,
        },
      };
    }
    case Types.TRANSACTION_LOADING: {
      return {
        ...state,
        transaction: {
          loading: action.payload,
          errors: {},
          data: {},
          success: false,
        },
      };
    }
    case Types.TRANSACTION_SUCCESS: {
      return {
        ...state,
        transaction: {
          loading: false,
          data: action.payload,
          errors: {},
          success: true,
        },
      };
    }
    case Types.TRANSACTION_FAILURE: {
      return {
        ...state,
        transaction: {
          loading: false,
          errors: action.payload,
          data: {},
          success: false,
        },
      };
    }
    case Types.TRANSACTION_CLEAR: {
      return {
        ...state,
        transaction: {
          loading: false,
          errors: {},
          data: {},
          success: false,
        },
      };
    }
    case Types.SUBSCRIPTION_STATUS_LOADING: {
      return {
        ...state,
        subscriptionStatus: {
          loading: action.payload,
          errors: {},
          data: {},
        },
      };
    }
    case Types.SUBSCRIPTION_STATUS_SUCCESS: {
      return {
        ...state,
        subscriptionStatus: {
          loading: false,
          data: action.payload,
          errors: {},
        },
      };
    }
    case Types.SUBSCRIPTION_STATUS_FAILURE: {
      return {
        ...state,
        subscriptionStatus: {
          loading: false,
          errors: action.payload,
          data: {},
        },
      };
    }
    default:
      return state;
  }
}

export default paymentReducer;
